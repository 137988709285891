<template>
  <Recycler icon="dots_loading" ref="recycler" v-if="news && news.length">
    <div class="featured-news">
      <div class="featured-news-title">
        <div class="main-news">
          <div class="featured-news-title-left" dcolor>NOTICIAS DESTACADAS</div>
          <div class="featured-news-title-right">
            <!-- <router-link :to="{ path: '/newsList', query: { wall: 'featured' } }" class="view-all-btn">View all</router-link> -->
          </div>
        </div>
      </div>
      <div class="featured-news-body">
        <div class="main-news __list" v-if="hasNews">
          <VueSlickCarousel class="featured-news-slide" v-bind="settings">
            <template #prevArrow="">
              <div class="featured-news-arrow __left"><iconic name="chevronLeft" /></div>
            </template>
            <div class="featured-news-item" v-for="(mynews, index) in news" :key="index + mynews._id">
              <NewsFeaturedCard class="card" :news="mynews" />
            </div>
            <template #nextArrow="">
              <div class="featured-news-arrow __right"><iconic name="chevronRight" /></div>
            </template>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
  </Recycler>
</template>

<script>
  import VueSlickCarousel from "vue-slick-carousel";
  export default {
    components: {
      NewsFeaturedCard: () => import("./NewsFeaturedCard.vue"),
      VueSlickCarousel: VueSlickCarousel,
    },
    data: function() {
      return {
        hasNews: false,
        error: null,
        settings: {
          arrows: true,
          dots: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          adaptiveHeight: true,
          cancelable: false,
          centerMode: false,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
        },
      };
    },
    methods: {
      loadNews: async function() {
        try {
          const host = `news${location.search || "?"}&featured=true&limit=6`;
          const { data } = await this.$api.get(`${host}`);
          this.newsLoaded(data);
        } catch (error) {
          this.$Swal.fire({ title: "Error", text: error });
          this.newsClose();
        }
      },
      newsLoaded: function(data) {
        if (!data.news || !data.news.length) {
          this.error = data.message || "No se encontraron noticias";
        } else {
          this.$store.state.news.featured.news = data.news;
          this.hasNews = true;
        }
      },
    },
    computed: {
      news: function() {
        return this.$store.state.news.featured.news;
      },
    },
    mounted: function() {
      this.loadNews();
    },
  };
</script>

<style lang="scss">
  .featured-news {
    position: relative;
    background-color: #fff;
    margin: $mpadding 0 0 0;
    &-title {
      font-family: $sec_font;
      margin: 0 0 $mpadding 0;
      &-left {
        font-size: 150%;
      }
      > * {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .view-all-btn {
        cursor: pointer;
      }
    }
    &-slide {
      .slick-track {
        display: flex;
      }
      .slick-slide {
        @include Margin(0, $mpadding, 0, $mpadding);
      }
      .slick-list {
        @include Margin(0, -$mpadding, 0, -$mpadding);
      }
    }
    &-arrow {
      width: $mpadding * 3;
      height: $mpadding * 3;
      font-size: 200%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: inherit;
      background: rgba(255, 255, 255, 0.8);
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
      z-index: 1;
      border-radius: 50%;
      &::before,
      &::after {
        display: none;
      }
      &:hover {
        color: $primary-color;
        background: rgba(255, 255, 255, 0.8);
      }
      &.__left {
        left: $mpadding;
      }
      &.__right {
        right: $mpadding;
      }
      &:active {
        opacity: 0.5;
      }
    }

    .news-card-title {
      .text {
        line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-body {
      position: relative;
    }
  }
</style>
